/** @jsx jsx */

import React, { useState, useEffect } from "react" // eslint-disable-line no-unused-vars
import PropTypes from "prop-types"
import { jsx, useThemeUI, useColorMode } from "theme-ui"
import Columns from "./columns"
import Column from "./column"
import Heading from "./heading"
import Box from "./box"
import Stack from "./stack"

import stampTrueLight from "../images/kurse/stamp-true-light.svg"
import stampFalseLight from "../images/kurse/stamp-false-light.svg"
import stampTrue from "../images/kurse/stamp-true.svg"
import stampFalse from "../images/kurse/stamp-false.svg"

const FeedbackHeaderBool = ({ title, level, children, confirmed }) => {
  const context = useThemeUI()
  const { theme } = context
  const [colorMode] = useColorMode()

  const [state, setState] = useState({
    readyToAnimate: false,
  })

  useEffect(() => {
    const timer = setTimeout(() => {
      setState({
        readyToAnimate: true,
      })
    }, 600)
    return () => clearTimeout(timer)
  }, [])

  return (
    <Columns
      space={[6, 12]}
      alignY="center"
      collapse={[true, false]}
      reverse={[false, true]}
    >
      <Column width="content">
        <Box
          aria-hidden="true"
          borderRadius="lg"
          border={4}
          borderColor="border"
          borderStyle="dashed"
          width={[16, 24, 24, 32]}
          height={[16, 24, 24, 32]}
          display="flex"
          alignY="center"
          alignX="center"
          textAlign="center"
          position="relative"
        >
          <Box
            position="absolute"
            left={[-6, -6, -12]}
            top={[-6, -6, -12]}
            bottom={[-6, -6, -12]}
            right={[-6, -6, -12]}
            display="flex"
            alignY="center"
            alignX="center"
          >
            <img
              src={
                confirmed === true
                  ? colorMode === "light"
                    ? stampTrueLight
                    : stampTrue
                  : colorMode === "light"
                  ? stampFalseLight
                  : stampFalse
              }
              alt={
                confirmed === true
                  ? "Stempel mit der Aufschrift Richtig"
                  : "Stempel mit der Aufschrift Falsch"
              }
              width="200"
              height="162"
              sx={{
                pointerEvents: "none",
                transform: state.readyToAnimate ? "scale(1)" : "scale(3)",
                opacity: state.readyToAnimate ? 1 : 0,
                transition: `transform ${theme.transitions["fast"]}, opacity ${theme.transitions["fast"]}`,
              }}
            />
          </Box>
        </Box>
      </Column>
      <Column>
        <Stack space={1}>
          <Heading
            level={6}
            as="span"
            color={confirmed ? "positive" : "negative"}
          >
            {confirmed ? "Richtig!" : "Leider daneben."}
          </Heading>
          {title && (
            <Heading as="h1" level={level}>
              {title}
            </Heading>
          )}
          {children}
        </Stack>
      </Column>
    </Columns>
  )
}

FeedbackHeaderBool.propTypes = {
  title: PropTypes.string,
  level: PropTypes.oneOfType([PropTypes.number, PropTypes.array]),
  children: PropTypes.node,
}

FeedbackHeaderBool.defaultProps = {
  level: 2,
}

export default FeedbackHeaderBool
